import React from 'react';
import Markdown from 'react-markdown';
import { markdown } from 'swapp-commons';
import Layout from '../../components/layout';
import markdownStyles from '../markdown.module.css';

const IndexPage: React.SFC = () => (
  <Layout>
    <Markdown
      source={markdown.ru.PrivacyPolicy}
      className={markdownStyles.content}
    />
  </Layout>
);

export default IndexPage;
